export default {
    URL: "https://app.funnelish.com/api/v1/", // "http://localhost:8000/api/v1/", // "https://app.funnelish.com/api/v1/",

    AnalyticsURL: "https://analytics.app.funnelish.com/", //
    DeveloperUrl: "http://localhost:8000/api/v1/", //
    VideosURL: "https://resources.app.funnelish.com/", //https://resources.app.funnelish.com/", // "http://localhost:8002/", // "https://videos.app.funnelish.com/", // "https://resources-service-beta3-ijwvlhvokq-ue.a.run.app/"

    EditorUrl: "https://editor.funnelish.com/", // "http://localhost:5173/", // "https://editor.funnelish.com/"

}
